import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"

import conduiteAccompagnee from "../../images/icons/conduite-accompagnee.svg";
import conduiteSupervisee from "../../images/icons/conduite-supervisee.svg";
import permisB from "../../images/icons/permis-b.svg";
import pdf from "../../images/icons/pdf.svg"

const NosFormationsPage = ({ data }) => (
  <Layout>
    <Seo title="Nos Formations" breadcrumb={[
      { url: "nos-formations", name: "Nos Formations"}
    ]} />
    <Breadcrumb paths={[{url: null, name:"Nos formations"}]} />
    <h1 className="h1">Nos Formations</h1>

    <div className="flex py-12">
      <div className="pr-8 md:pr-16 py-6 flex-shrink-0">
        <img src={permisB} width="80" className="" alt="" />
      </div>
      <div className="">
        <h2>Permis B</h2>
        <div
          dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.permis_b }}
          className="mb-6 md:w-3/4 html-content"
        ></div>
        <Link to="/nos-formations/permis-b" className="btn">En savoir plus</Link>
      </div>
    </div>

    <div className="flex py-12">
      <div className="pr-8 md:pr-16 py-6 flex-shrink-0">
        <img src={conduiteAccompagnee} width="80" className="" alt="" />
      </div>
      <div className="">
        <h2>Conduite Accompagnée (AAC)</h2>
        <div
          dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.conduite_accompagnee }}
          className="mb-6 md:w-3/4 html-content"
        ></div>
        <Link to="/nos-formations/conduite-accompagnee" className="btn">En savoir plus</Link>
      </div>
    </div>

    <div className="flex py-12">
      <div className="pr-8 md:pr-16 py-6 flex-shrink-0">
        <img src={conduiteSupervisee} width="80" className="" alt="" />
      </div>
      <div className="">
        <h2>Conduite Supervisée</h2>
        <div
          dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.conduite_supervisee }}
          className="mb-6 md:w-3/4 html-content"
        ></div>
        <Link to="/nos-formations/conduite-supervisee" className="btn">En savoir plus</Link>
      </div>
    </div>


    <div className="text-center mt-12">
      <div className="card inline-block">
        <h3 className="uppercase mb-3">Les tarifs</h3>
        <p className="mb-8">Téléchargez nos fiches de tarifs</p>
        {data.strapiNosFormations.pdf_files.map(file => (
          <a href={file.localFile.url} className="btn mx-3 mb-8" target="_blank" rel="noreferrer">
            <img src={pdf} width="20" className="inline mr-2" alt="" />
            {file.alternativeText}
          </a>
        ))}
      </div>
    </div>
  </Layout>
)

export default NosFormationsPage

export const pageQuery = graphql`
  query FormationsQuery {
    strapiNosFormations {
      permis_b
      conduite_accompagnee
      conduite_supervisee
      pdf_files {
        alternativeText
        name
        localFile {
          url
        }
      }
    }
  }
`
